import React from 'react'
import {Link} from 'react-router-dom'

const PortfolioListRecent = () => {
  return (
    <>
       <div class="gallery">
        <div class="row">

            <div class="col-lg-4 items">
                <div class="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                    <div class="img w-100 h-100">
                        <Link to="/portfolio/dhado-pay">
                            <img src="assets/imgs/works/1/dhado-web.webp" alt="" />
                        </Link>
                    </div>
                   
                </div>
            </div>

            <div class="col-lg-4 items">
                <div class="item mt-50 wow fadeInUp" data-wow-delay=".4s">
                    <div class="img">
                        <Link to="/portfolio/achira-store">
                            <img src="assets/imgs/works/2/achira.webp" alt="" />
                        </Link>
                    </div>
                   
                   
                </div>
            </div>

            <div class="col-lg-4 items">
                <div class="item mt-50 wow fadeInUp" data-wow-delay=".6s">
                    <div class="img">
                        <Link to="/portfolio/maamul-software">
                            <img src="assets/imgs/works/3/maamul.webp" alt="" />
                        </Link>
                    </div>
                    
                </div>
            </div>

            <div className="col-lg-12">
                <div className="row">
                <div class="col-lg-4 items">
                <div class="item mt-50 wow fadeInUp" data-wow-delay=".6s">
                    <div class="img">
                        <Link to="/portfolio/xidig-web">
                            <img src="assets/imgs/works/4/xidig-web.webp" alt="" />
                        </Link>
                    </div>
                    
                </div>
            </div>
                <div class="col-lg-4 items">
                    <div class="item mt-50 wow fadeInUp" data-wow-delay=".6s">
                        <div class="img">
                            <Link to="/portfolio/bidhaan-booking">
                                <img src="assets/imgs/works/5/bidhaan.webp" alt="" />
                            </Link>
                        </div>
                        
                    </div>
                </div>

                <div class="col-lg-4 items">
                    <div class="item mt-50 wow fadeInUp" data-wow-delay=".6s">
                        <div class="img">
                            <Link to="/portfolio/buundo-jobs">
                                <img src="assets/imgs/works/6/buundo-jobs.webp" alt="" />
                            </Link>
                        </div>
                        
                    </div>
                </div>
                </div>
            </div>
            

           

        </div>
    </div>
    </>
  )
}

export default PortfolioListRecent
