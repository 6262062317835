import React from 'react'
import { Helmet } from 'react-helmet';

import Breadcrump from '../components/Breadcrump'
import Hero from '../components/About/Hero'
import About from '../components/About/AboutLong'
import WorkExp from '../components/About/WorkExp'
import Education from '../components/About/Education'
import Contact from "../components/Home/Contact"

const AboutPage = () => {
  return (
    <div>
      <Breadcrump title="About Page" page="about" />
      
      <div className="container">
        <About />
        <WorkExp />
        <Education />
        <Contact />
      </div>

      

    </div>
  )
}

export default AboutPage
