

const Education = () => {
  return (
    <>
        <div class="row">
            <div class="col-lg-12 mt-80">
                <h6 class="sub-title opacity-7 mb-15">Education</h6>
                <h3>Educational <span class="text-primary">Background</span> </h3>
            </div>
        </div>

        <div class="main-card mb-3 card">
            <div class="card-body">
               
                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    {/* Plasma UNiversity */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-success"></i>
                            </span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <h4 class="timeline-title">Project Management MBAPM - EMBA - <a href="" className='text-primary' >Plasma University</a></h4>
                                
                                <span class="vertical-timeline-element-date">Dec 2023 - Present</span>
                            </div>
                        </div>
                    </div>

                    {/* SIMAD University */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">BSc. Computer Science - <a href="" className='text-primary' >SIMAD University</a></h4>

                                <span class="vertical-timeline-element-date">2016 - 2020</span>
                            </div>
                        </div>
                    </div>  

                    {/* IML SIMAD */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">English Skills Program - <a href="o" className='text-primary' >IML SIMAD</a></h4>
                                <p>
                                    <ul>
                                        <li> 
                                            Completed a comprehensive five-level English language 
                                            program through MacMillan, encompassing Foundation, 
                                            Beginner, Elementary, Advanced, and Mastery levels, 
                                            with a focus on enhancing reading and writing skills.
                                        </li>
                                        
                                    </ul>
                                </p>
                                <span class="vertical-timeline-element-date">2017 - 2020</span>
                            </div>
                        </div>
                    </div>  

                    {/* edX */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">Web programming with Python & JavaScript - <a href="" className='text-primary' >edX</a></h4>
                                <p>
                                    <ul>
                                        <li> 
                                            Throughout this course, I mastered multiple programming languages and 
                                            frameworks, including Flask, Django, Git, and JavaScript. Notably, 
                                            I completed five projects, demonstrating practical application and 
                                            deepening my technical expertise.
                                        </li>
                                       
                                    </ul>
                                </p>
                                <span class="vertical-timeline-element-date">March, 2020</span>
                            </div>
                        </div>
                    </div>  

                    {/* Cloud computing Edx */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">Introduction to Cloud Computing - <a href="#" className='text-primary' >edX</a></h4>
                                <p>
                                    
                                </p>
                                <span class="vertical-timeline-element-date">Aug, 2024</span>
                            </div>
                        </div>
                    </div>  

                    {/* AI */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">AI for every one - <a href="#" className='text-primary' >edX</a></h4>
                                <p>
                                    
                                </p>
                                <span class="vertical-timeline-element-date">June, 2021</span>
                            </div>
                        </div>
                    </div>  

                    {/* AI */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">Introduction to IoT - <a href="#" className='text-primary' >Cisco Networking Academy     </a></h4>
                                <p>
                                    
                                </p>
                                <span class="vertical-timeline-element-date">July, 2021</span>
                            </div>
                        </div>
                    </div>  


                    
                </div>
            </div>
        </div>  
    </>
  )
}

export default Education
