import React from 'react'

const Brands = () => {
  return (
    <>
        <div class="sec-box services section-padding bord-thin-bottom" data-scroll-index="1">
            <div class="sec-head wow fadeInUp" style={{visibility: "visible", animationName: "fadeInUp"}}>
                <div class="row justify-content-center">
                    <div class="col-lg-6 text-center">
                        <h6 class="sub-title opacity-7 mb-15">Brands</h6>
                        <h3>Top Companies <span class="main-color">Worked</span> </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="sec-box services section-padding bord-thin-bottom" data-scroll-index="1">
            <div class="sec-head mb-80 wow fadeInUp" style={{visibility: "visible", animationName: "fadeInUp"}}>

                <div className="row ">
                    <div class="col-md-4 ">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".2s" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <span class="opacity-7">
                                <img src="assets/imgs/brands/xidig.png" alt="" />
                            </span>
                           
                            <div class="bord-color"></div>
                        </div>
                    </div>
                    <div class="col-md-4 ">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".2s" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <span class="  opacity-7">
                                <img src="assets/imgs/brands/pujowhar.png" alt="" />
                            </span>
                           
                            <div class="bord-color"></div>
                        </div>
                    </div>

                    <div class="col-md-4 ">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".2s" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <span class="  opacity-5">
                                <img src="assets/imgs/brands/amtel.jpg" alt="" />
                            </span>
                           
                            <div class="bord-color"></div>
                        </div>
                    </div>

                    <div class="col-md-4 ">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".2s" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <span class="  opacity-7">
                                <img src="assets/imgs/brands/somteso.png" alt="" />
                            </span>
                           
                            <div class="bord-color"></div>
                        </div>
                    </div>
                    <div class="col-md-4 ">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".2s" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <span class="  opacity-7">
                                <img src="assets/imgs/brands/eastech.png" alt="" />
                            </span>
                           
                            <div class="bord-color"></div>
                        </div>
                    </div>

                    <div class="col-md-4 ">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".2s" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <span class="  opacity-7 text-center">
                                <img src="assets/imgs/brands/zakariye.png" alt="" />
                            </span>
                           
                            <div class="bord-color"></div>
                        </div>
                    </div>

               


                </div>

            </div>
        </div>

       
    </>
  )
}

export default Brands
