import React from 'react'
import Breadcrump from '../components/Breadcrump'
import Cform from '../components/Contact/Cform'

const ContactPage = () => {
  return (
    <div>
      <Breadcrump title="Contact Page" page='contact' />
      <Cform />
    </div>
  )
}

export default ContactPage
