// TitleManager.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TitleManager = () => {
  const location = useLocation();

  useEffect(() => {
    // Set document title based on the route
    switch (location.pathname) {
      case '/about':
        document.title = 'About - Yakub Ahmed';
        break;
      case '/portfolio':
        document.title = 'Portfolio - Yakub Ahmed';
        break;
      case '/courses':
        document.title = 'Courses - Yakub Ahmed';
        break;
      case '/contact-me':
        document.title = 'Contact Me - Yakub Ahmed';
        break;
      // Add more cases for other routes if needed
      default:
        document.title = 'Home - Yakub Ahmed';
        break;
    }
  }, [location]);

  return null;  // This component doesn't render anything
};

export default TitleManager;
