import React from 'react'

const Hero = () => {
  return (
    <div className='container'>

<div className="col-md-12 d-flex justify-content-center" style={{marginTop:"-150px", filter:"blur(0)"}}>
            <div className="stauts ">
                <div className="d-flex align-items-center">
                  <div className="mr-40">
                    <div className="d-flex align-items-center">
                      <h2>4+</h2>
                      <p>
                        Years <br /> of Experance
                      </p>
                    </div>
                  </div>
                  <div className="mr-40">
                    <div className="d-flex align-items-center">
                      <h2>2</h2>
                      <p>
                        Times <br /> 🏆Awards
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="butn-presv">
                      <a href="#0" className="butn butn-md butn-bord radius-5 skew">
                        <span>Download C.V</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

       
    </div>
  )
}

export default Hero
