import Breadcrump from "../components/Breadcrump"


const Zakariye = () => {
  return (
    <>
        <Breadcrump title="Zakariye" page="zakariye-web"  />
        <section class="sec-box project section-padding radius-15">
            <div class="row justify-content-center">
                <div class="col-lg-11">
                    
                    <div class="img mb-80">
                        <img src="assets/imgs/works/7/zakariye.webp" alt="" class="radius-5" />
                    </div>
                    <div class="row justify-content-center">
                       
                        <div class="col-lg-7">
                        <div class="cont md-mb50">
                                <h3 class="mb-15 fw-500"> <strong>Zakariye.</strong> </h3>
                                <div class="img mb-80">
                                    <img src="assets/imgs/works/7/zakariye-full.png" alt="" class="radius-5" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="info sub-bg" id="sticky_item">
                                <ul>
                                    <li class="mb-30">
                                        <span class="sub-title"><i class="far fa-calendar-alt mr-10"></i> Date
                                            :</span>
                                        <p> </p>
                                    </li>
                                    <li class="mb-30">
                                        <span class="sub-title"><i class="fas fa-list-ul mr-10"></i> Categories
                                            :</span>
                                        <p>Web design</p>
                                    </li>
                                    <li class="mb-30">
                                        <span class="sub-title"><i class="far fa-user mr-10"></i> Client :</span>
                                        <p>Zakariye </p>
                                    </li>
                                    <li>
                                        <span class="sub-title"><i class="fas fa-globe mr-10"></i> Website :</span>
                                        <p><a href="https://zakariye.com" target="_blank" rel="noopener noreferrer">zakariye.com</a></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="imgs mt-80">
                <div class="row justify-content-center">
                    <div class="col-lg-11">
                        <div class="row md-marg">
                            <div class="col-md-6">
                                <div class="img sm-mb30">
                                    <img src="assets/imgs/works/project/2.png" alt="" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="img">
                                    <img src="assets/imgs/works/project/3.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="next-prev">
                <div class="row justify-content-center">
                    <div class="col-lg-11">
                        <div class="d-flex align-items-center mt-80 pt-80 bord-thin-top">
                            <div class="prev">
                                <h6 class="sub-title">
                                    <a href="#"><i class="fas fa-long-arrow-alt-left"></i> Prev Project</a>
                                </h6>
                            </div>
                            <div class="next ml-auto">
                                <h6 class="sub-title">
                                    <a href="#">next Project <i class="fas fa-long-arrow-alt-right"></i></a>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Zakariye
