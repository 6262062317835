// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to the top when the route changes
  }, [pathname]);  // The effect runs every time the pathname changes

  return null;
};

export default ScrollToTop;
