import React from 'react'
import Breadcrump from '../components/Breadcrump'
import Course from "../components/Home/Course"

const CoursesPage = () => {
  return (
    <>
      <Breadcrump title="Courses page" page="courses" />
      <Course />
    </>
  )
}

export default CoursesPage
