import React from 'react'
import Breadcrump from '../components/Breadcrump'
const Maamul = () => {
  return (
    <>
        <Breadcrump title="Maamul Software" page="maamul"  />
        <section class="sec-box project section-padding radius-15">
            <div class="row justify-content-center">
                <div class="col-lg-11">
                    <div class="img mb-80">
                        <img src="assets/imgs/works/3/maamul.webp" alt="" class="radius-5" />
                    </div>
                    <div class="row justify-content-center">
                       
                        <div class="col-lg-7">
                        <div class="cont md-mb50">
                                <h3 class="mb-15 fw-500"> <strong>Maamul Software.</strong> </h3>
                                <p>
                                    The Maamul Software is a powerful, all-in-one solution designed by Yakub Ahmed to 
                                    streamline your business operations and provide total control over your inventory. 
                                    This software is built with a focus on flexibility, ease of use, and scalability, 
                                    making it suitable for businesses of all sizes.
                                </p>
                               
                                <div class="mt-30">
                                   
                                    <ul class="rest list-arrow mt-50">
                                        <li>
                                            <span class="icon">
                                                <svg width="100%" height="100%" viewBox="0 0 9 8" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                                                        fill="#fff"></path>
                                                </svg>
                                            </span>
                                            <h6 class="inline fz-18">Sales Management.</h6>
                                        </li>
                                        <li class="mt-20">
                                            <span class="icon">
                                                <svg width="100%" height="100%" viewBox="0 0 9 8" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                                                        fill="#fff"></path>
                                                </svg>
                                            </span>
                                            <h6 class="inline fz-18">Purchase Management.</h6>
                                        </li>
                                        <li class="mt-20">
                                            <span class="icon">
                                                <svg width="100%" height="100%" viewBox="0 0 9 8" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                                                        fill="#fff"></path>
                                                </svg>
                                            </span>
                                            <h6 class="inline fz-18">Customer Management.</h6>
                                        </li>
                                        <li class="mt-20">
                                            <span class="icon">
                                                <svg width="100%" height="100%" viewBox="0 0 9 8" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                                                        fill="#fff"></path>
                                                </svg>
                                            </span>
                                            <h6 class="inline fz-18">Supplier Management.</h6>
                                        </li>
                                        <li class="mt-20">
                                            <span class="icon">
                                                <svg width="100%" height="100%" viewBox="0 0 9 8" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                                                        fill="#fff"></path>
                                                </svg>
                                            </span>
                                            <h6 class="inline fz-18">Accounts Management.</h6>
                                        </li>
                                        <li class="mt-20">
                                            <span class="icon">
                                                <svg width="100%" height="100%" viewBox="0 0 9 8" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                                                        fill="#fff"></path>
                                                </svg>
                                            </span>
                                            <h6 class="inline fz-18">Stock Control.</h6>
                                        </li>
                                        <li class="mt-20">
                                            <span class="icon">
                                                <svg width="100%" height="100%" viewBox="0 0 9 8" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                                                        fill="#fff"></path>
                                                </svg>
                                            </span>
                                            <h6 class="inline fz-18">User Management & Roles.</h6>
                                        </li>
                                        <li class="mt-20">
                                            <span class="icon">
                                                <svg width="100%" height="100%" viewBox="0 0 9 8" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                                                        fill="#fff"></path>
                                                </svg>
                                            </span>
                                            <h6 class="inline fz-18">Reports & Analytics.</h6>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="info sub-bg" id="sticky_item">
                                <ul>
                                    <li class="mb-30">
                                        <span class="sub-title"><i class="far fa-calendar-alt mr-10"></i> Date
                                            :</span>
                                        <p> </p>
                                    </li>
                                    <li class="mb-30">
                                        <span class="sub-title"><i class="fas fa-list-ul mr-10"></i> Categories
                                            :</span>
                                        <p>Software Deve..</p>
                                    </li>
                                    <li class="mb-30">
                                        <span class="sub-title"><i class="far fa-user mr-10"></i> Client :</span>
                                        <p>Xidig Tech </p>
                                    </li>
                                    <li>
                                        <span class="sub-title"><i class="fas fa-globe mr-10"></i> Website :</span>
                                        <p><a href="http://maamul.so" target="_blank" rel="noopener noreferrer">demo.maamul.so</a></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="imgs mt-80">
                <div class="row justify-content-center">
                    <div class="col-lg-11">
                        <div class="row md-marg">
                            <div class="col-md-6">
                                <div class="img sm-mb30">
                                    <img src="assets/imgs/works/project/2.png" alt="" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="img">
                                    <img src="assets/imgs/works/project/3.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="next-prev">
                <div class="row justify-content-center">
                    <div class="col-lg-11">
                        <div class="d-flex align-items-center mt-80 pt-80 bord-thin-top">
                            <div class="prev">
                                <h6 class="sub-title">
                                    <a href="#"><i class="fas fa-long-arrow-alt-left"></i> Prev Project</a>
                                </h6>
                            </div>
                            <div class="next ml-auto">
                                <h6 class="sub-title">
                                    <a href="#">next Project <i class="fas fa-long-arrow-alt-right"></i></a>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Maamul
