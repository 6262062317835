import React from 'react'

export default function Footer() {
  return (
    <div>
      <footer className="pb-30 pt-30">
  <div className="container">
    <div className="row">
     
      <div className="col-12">
        <div className="text-center">
          <p className="fz-13">
            © 2024 Yakub Ahmed{" "}
            
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}
