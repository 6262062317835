import React from 'react'

const WorkExp = () => {
  return (
    <>
         <div class="row">
            <div class="col-lg-12 mt-80">
                <h6 class="sub-title opacity-7 mb-15">Experience</h6>
                <h3>My <span class="text-primary">Professional Journey
                </span> </h3>
            </div>
        </div>

        <div class="main-card mb-3 card">
            <div class="card-body">
               
                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    {/* Xidig Tech */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-success"></i>
                            </span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <h4 class="timeline-title">CEO - <a href="https://www.xidigtech.com" className='text-primary' target='_blank'>Xidig Tech</a></h4>
                                <p>
                                    <ul>
                                        <li> Developed custom inventory management software, optimizing operations.</li>
                                        <li> Designed impactful social media posters, enhancing brand visibility. </li>
                                        <li> Collaborated with famous Somali content creators to create websites, elevating online presence. </li>
                                    </ul>
                                </p>
                                <span class="vertical-timeline-element-date">Sep 2022 - Present</span>
                            </div>
                        </div>
                    </div>

                    {/* Plasma University */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">Lecturer - <a href="https://www.xidigtech.com" className='text-primary' target='_blank'>Plasma University</a></h4>

                                <p>
                                    <ul>
                                        <li> 
                                            Implemented innovative teaching strategies to foster active learning, utilizing discussion-based lectures, group activities, and multimedia presentations.
                                        </li>
                                        <li>
                                            Taught a diverse range of subjects, including ASP.NET, PHP, E-commerce, Data Structures, JAVA, SQL, and Networking, demonstrating broad expertise. 
                                        </li>
                                    </ul>
                                </p>
                                <span class="vertical-timeline-element-date">Mar 2021 - Present</span>
                            </div>
                        </div>
                    </div>  

                    {/* Easy Tech */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">DevOps Officer - <a href="https://www.easytech.so" className='text-primary' target='_blank'>EasyTech</a></h4>
                                <p>
                                    <ul>
                                        <li> 
                                        Developed a diverse range of websites, including eLearning platforms, NGO sites, and corporate websites, enhancing user engagement and functionality.
                                        </li>
                                        <li>
                                        Enhanced features of an accounting SAAS system using Node.js and Angular, significantly improving system performance and user satisfaction.
                                        </li>
                                        <li>
                                        Designed and implemented a police record-keeping system, optimizing data management and security for law enforcement agencies.
                                        </li>
                                    </ul>
                                </p>
                                <span class="vertical-timeline-element-date">Nov 2023 - Feb 2024</span>
                            </div>
                        </div>
                    </div>  

                    {/* Amtel */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">Telecom Engineer - <a href="https://amtelkom.com/" className='text-primary' target='_blank'>Amtel</a></h4>
                                <p>
                                    <ul>
                                        <li> 
                                            As a telecom engineer, i conducted research in a variety of applications and test the
                                            products for feasibility. Also i have involved in the design, development, and testing of new
                                            technology to improve networks and the experience of customers using them.
                                        </li>
                                       
                                    </ul>
                                </p>
                                <span class="vertical-timeline-element-date">Oct 2021 - Sep 2022</span>
                            </div>
                        </div>
                    </div>  

                    {/* Somteso */}
                    <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                                <i class="badge badge-dot badge-dot-xl badge-warning"> </i>
                            </span>
                            
                            <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">Software Developer - <a href="https://somteso.so/" className='text-primary' target='_blank'>Somteso</a></h4>
                                <p>
                                    <ul>
                                        <li> 
                                            Collaborated with cross-functional teams to develop and deliver high-quality software solutions, consistently meeting aggressive project timelines.
                                        </li>
                                        <li> 
                                            Designed and implemented a custom content management system using PHP and MySQL, enhancing digital content handling and user interaction.
                                        </li>
                                        <li> 
                                            Participated in the development of key systems, including a school management system and a water supply management system, contributing to significant operational improvements.
                                        </li>
                                       
                                    </ul>
                                </p>
                                <span class="vertical-timeline-element-date">Jun 2019 - Jul 2020</span>
                            </div>
                        </div>
                    </div>  


                    
                </div>
            </div>
        </div>     
    </>
  )
}

export default WorkExp
