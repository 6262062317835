import React from 'react'
import Breadcrump from '../components/Breadcrump'
import AllProjects from '../components/AllProjects'

const PortfolioPage = () => {
  return (
    <div>
       <Breadcrump title="Portfolio Page" page="portfolio" />
       <div className="container">
        <AllProjects />
       </div>
    </div>
  )
}

export default PortfolioPage
