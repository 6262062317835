import React from 'react'
import { Link } from 'react-router-dom'
const Breadcrump = (probs) => {
  return (
    <>
    <div className="container sec-box services  bord-thin-bottom">
        <div className="row">
            <div className="col-md-12">
                <div className="item mb-40 wow fadeIn" data-wow-delay=".2s" tyle={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeIn' }}>
                
                    <h6 className="text-u ls1 mb-15 text-center " style={{color:"white"}}> <strong>{probs.title}</strong> </h6>
                    <p className='text-center'> <Link to="/"><span className='text-primary'>Home</span></Link> <strong> {'>'} </strong> {probs.page}</p>
                    <div className="bord-color"></div>
                </div>
            </div>

        </div>
    </div>
    </>
  )
}

export default Breadcrump
