import {Link} from "react-router-dom"
import PortfolioListRecent from "../PortfolioListRecent"

const Portfolio = () => {
  return (
    <>
    <div class="sec-box portfolio section-padding" data-scroll-index="3">
    <div class="sec-head mb-30">
        <div class="row">
            <div class="col-lg-6">
                <h6 class="sub-title opacity-7 mb-15">Portfolio</h6>
                <h3>My Work<br /> <span className="text-primary">Showcase</span>      </h3>
            </div>
        
        </div>
    </div>
    
   <PortfolioListRecent />

    <div className="col-md-12 items mt-50 text-center">
            <div className="butn-presv">
                    <Link to="/portfolio" className="butn-primary butn butn-md butn-bord radius-5 ">
                        <span>Learn more </span>
                    </Link>
                </div>
            </div>
</div>
    </>
  )
}

export default Portfolio