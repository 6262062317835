import React from 'react'
import {Link} from 'react-router-dom'
import PortfolioListRecent from './PortfolioListRecent'
import Contact from '../components/Home/Contact'

const AllProjects = () => {
  return (
    <>
        <div class="sec-box portfolio section-padding" data-scroll-index="3">
            <div class="sec-head mb-30">
                <div class="row">
                    <div class="col-lg-12">
                        <h6 class="sub-title opacity-7 mb-15">Portfolio</h6>
                        <h3>My Work <span className="text-primary"> Showcase</span>      </h3>
                    </div>
                    
                </div>
            </div>
    
            <PortfolioListRecent />

            <div class="gallery">
                <div class="row">
                    {/* Zakariye */}
                    <div class="col-lg-4 items">
                        <div class="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                            <div class="img w-100 h-100">
                                <Link to="/portfolio/zakariye">
                                    <img src="assets/imgs/works/7/zakariye.webp" alt="" />
                                </Link>
                            </div>
                        
                        </div>
                    </div>
                    {/* Ashqarar */}
                    <div class="col-lg-4 items">
                        <div class="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                            <div class="img w-100 h-100">
                                <Link to="/portfolio/ashqarar">
                                    <img src="assets/imgs/works/8/ashaqarar.webp" alt="" />
                                </Link>
                            </div>
                        
                        </div>
                    </div>
                    {/* Submalco */}
                    <div class="col-lg-4 items">
                        <div class="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                            <div class="img w-100 h-100">
                                <Link to="/portfolio/submalco">
                                    <img src="assets/imgs/works/9/submalco.webp" alt="" />
                                </Link>
                            </div>
                        
                        </div>
                    </div>
                    {/* Fatah Tech */}
                    <div class="col-lg-4 items">
                        <div class="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                            <div class="img w-100 h-100">
                                <Link to="/portfolio/fatah-tech">
                                    <img src="assets/imgs/works/10/fatah.png" alt="" />
                                </Link>
                            </div>
                        
                        </div>
                    </div>
                    {/* SmartPLus */}
                    <div class="col-lg-4 items">
                        <div class="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                            <div class="img w-100 h-100">
                                <Link to="/portfolio/smartplus-advert">
                                    <img src="assets/imgs/works/11/smartplus.png" alt="" />
                                </Link>
                            </div>
                        
                        </div>
                    </div>
                    {/* Dalboon */}
                    <div class="col-lg-4 items">
                        <div class="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                            <div class="img w-100 h-100">
                                <Link to="/portfolio/dalboon">
                                    <img src="assets/imgs/works/12/dalboon-web.webp" alt="" />
                                </Link>
                            </div>
                        
                        </div>
                    </div>

                </div>
            </div>

            <Contact />
        </div>
    </>
  )
}

export default AllProjects
