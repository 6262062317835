import {Link} from "react-router-dom"

const Contact = () => {
  return (
    <>
      
<div class="sec-box services section-padding bord-thin-bottom" data-scroll-index="5">
    <div class="row">
        <div class="col-lg-12">
            <div class="item mb-40 wow fadeIn" data-wow-delay=".2s"  style={{visibility: "visible", animationDelay: "0.4s", animationName: "fadeIn"}}>
            <div class="bord-color"></div>
               
                <p className="text-justify text-center">
                    Ready to bring your ideas to life? Let's collaborate to create <br />
                    something exceptional. Get in touch today to start your next project! <br />
                <Link to='/contact-me' className="btn btn-primary btn-lg mt-4 ext-u ls1 mb-15 " >Ready to Get Started?</Link>
                </p>
                
                
            </div>
        

        </div>
       
    </div>
</div>

    </>
  )
}

export default Contact
