import { Outlet } from "react-router-dom"
import Header from "../components/Header"
import Footer from "../components/Footer"
import TitleManager from "../components/TitleManager"
import ScrollToTop from "../components/ScrollToTop"



const MainLayout = () => {
  return (
    <div>
       <TitleManager />
      <Header />
      <Outlet />
      <Footer />
      <ScrollToTop />
    </div>
  )
}

export default MainLayout
