import Hero from "./Hero"

const AboutLong = () => {
  return (
    <>
        <div className='row my-4 d-flex align-items-center mt-80'>

            <div className='col-md-8 py-4 px-4 my-4'>
                <h6 class="sub-title opacity-7 mb-15 ">About Me</h6>
                <h3>Bringing Ideas to Life <br />Through <span className="text-primary">Code</span> & <span className="text-primary">Collaboration</span></h3>
                <p className='text-justify mt-20'>
                I'm Yakub Ahmed, a Full Stack Web Developer with over 4 years of experience in crafting web applications that offer exceptional user experiences. My expertise in both front-end and back-end technologies enables me to seamlessly blend creativity with technical proficiency.
                <br /><br />
                I’ve collaborated with cross-functional teams to deliver high-quality software solutions, including custom content management and school management systems. My diverse experience as a Software Developer, University Lecturer, Telecom Engineer, and Freelancer equips me to excel in various technical environments.
                <br /><br />
                As the Founder and CEO of Xidig Tech, I lead the development of innovative software solutions tailored to businesses of all sizes. I’m also passionate about teaching and mentoring future developers.
                <br /><br />
                I hold a BSc in Computer Science from SIMAD University and have completed certifications from Cisco Network Academy and HarvardX. My commitment to continuous learning ensures I stay ahead in the dynamic field of web development.
                </p>
               
              
            </div>

            <div className="col-md-4">
              <img src='assets/imgs/yakubaward.png' />
            </div>

            
            <div className='col-md-12 mt-80' style={ { borderRadius: "20px", filter:"blur(8px)" } }>
                <img src="assets/imgs/about-top.webp" alt=""   />
            </div>

            <Hero />

        </div>
    </>
  )
}

export default AboutLong
