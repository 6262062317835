

const Skills = () => {
  return (
    <>

                <div class="sec-box services section-padding bord-thin-bottom" data-scroll-index="1">
                    <div class="sec-head mb-80 wow fadeInUp" style={{visibility: "visible", animationName: "fadeInUp"}}>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 text-center">
                            <h6 class="sub-title opacity-7 mb-15">Skills</h6>
                            <h3>My <span class="main-color">Specialties</span> </h3>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".2s"  style={{visibility: "visible", animationDelay: "0.4s", animationName: "fadeIn"}}>
                            <span class="icon-img-70 mb-30 opacity-7">
                                <img src="assets/imgs/serv-img/2.png" alt="" />
                            </span>
                            <h6 class="text-u ls1 mb-15"> <strong>Web Developer</strong> </h6>
                            <p className="text-justify">
                                Experienced in PHP, JavaScript, Python, 
                                and MySQL, I create custom, responsive web 
                                applications tailored to your needs.

                            </p>
                            <div class="bord-color"></div>
                        </div>
                    </div>


                    <div class="col-md-4">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".4s" style={{visibility: "visible", animationDelay: "0.4s", animationName: "fadeIn"}}>
                            <span class="icon-img-70 mb-30 opacity-7">
                                <img src="assets/imgs/serv-img/lecturer.png" alt="" />
                            </span>
                            <h6 class="text-u ls1 mb-15"> <strong>Lecturer</strong> </h6>
                            <p>
                                Experienced lecturer specializing in programming, 
                                networking, and web development, offering engaging 
                                and effective instruction in computer science.

                            </p>
                            <div class="bord-color"></div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".4s" style={{visibility: "visible", animationDelay: "0.4s", animationName: "fadeIn"}}>
                            <span class="icon-img-70 mb-30 opacity-7">
                                <img src="assets/imgs/serv-img/1.png" alt="" />
                            </span>
                            <h6 class="text-u ls1 mb-15"> <strong>Graphic Designer</strong></h6>
                            <p>
                                Creative graphic designer offering visually compelling 
                                designs, including logos, branding, and digital content, 
                                tailored to elevate your brand.

                            </p>
                            <div class="bord-color"></div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="item mb-40 wow fadeIn" data-wow-delay=".4s" style={{visibility: "visible", animationDelay: "0.4s", animationName: "fadeIn"}}>
                            <h6 class="text-u ls1 mb-15 text-center"> <strong>#Tech Stack</strong></h6>
                            <button type="button" className="btn btn-dark btn-spacing">JavaScript</button>
                            <button type="button" className="btn btn-dark btn-spacing">React JS</button>
                            <button type="button" className="btn btn-dark btn-spacing">Node Js</button>
                            <button type="button" className="btn btn-dark btn-spacing">Express</button>
                            <button type="button" className="btn btn-dark btn-spacing">Php</button>
                            <button type="button" className="btn btn-dark btn-spacing">MySQL</button>
                            <button type="button" className="btn btn-dark btn-spacing">Postgresql</button>
                            <button type="button" className="btn btn-dark btn-spacing">Oracle</button>
                            <button type="button" className="btn btn-dark btn-spacing">SQL Server</button>
                            <button type="button" className="btn btn-dark btn-spacing">Html & CSS</button>
                            <button type="button" className="btn btn-dark btn-spacing">C#</button>
                            <button type="button" className="btn btn-dark btn-spacing">Python</button>
                            <button type="button" className="btn btn-dark btn-spacing">Java</button>
                            <button type="button" className="btn btn-dark btn-spacing">Flask</button>
                            <button type="button" className="btn btn-dark btn-spacing">ASP.NET</button>
                            <button type="button" className="btn btn-dark btn-spacing">C++</button>
                            <button type="button" className="btn btn-dark btn-spacing">WordPress</button>
                            <button type="button" className="btn btn-dark btn-spacing">Photoshop</button>
                            


                        </div>
                    </div>

                
                </div>
            </div>

    </>
  )
}

export default Skills