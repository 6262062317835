import {
  Route, 
  createBrowserRouter, 
  createRoutesFromElements, 
  RouterProvider 
} from 'react-router-dom';


import MainLayout from './layouts/MainLayout';
import HomePage from './pages/HomePage';
import About from './pages/AboutPage';
import PortfolioPage from './pages/PortfolioPage';
import CoursesPage from './pages/CoursesPage';
import ContactPage from './pages/ContactPage';
import NotFound from './pages/NotFound';
import DhadoPay from './projects/DhadoPay';
import ScrollToTop from './components/ScrollToTop';  // Import ScrollToTop
import AchiraStore from './projects/AchiraStore';
import Maamul from './projects/Maamul';
import XidigWeb from './projects/XidigWeb';
import BidhaanBooking from './projects/BidhaanBooking';
import BuundoJobs from './projects/BuundoJobs';
import Zakariye from './projects/Zakariye';
import TitleManager from './components/TitleManager'
import Ashqarar from './projects/Ashqarar';
import Submalco from './pages/Submalco';
import FatahTech from './projects/FatahTech';
import SmartPlus from './projects/SmartPlus';
import Dalboon from './projects/Dalboon';



function App() {
  
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<MainLayout />}>

        <Route index  element={<HomePage />} />
        <Route path='/about' element={<About />} />
        <Route path='/portfolio' element={<PortfolioPage />} />
        <Route path='/courses' element={<CoursesPage />} />
        <Route path='/contact-me' element={<ContactPage />} />
        <Route path='/portfolio/dhado-pay' element={<DhadoPay />} />
        <Route path='/portfolio/achira-store' element={<AchiraStore />} />
        <Route path='/portfolio/maamul-software' element={<Maamul />} />
        <Route path='/portfolio/xidig-web' element={<XidigWeb />} />
        <Route path='/portfolio/bidhaan-booking' element={<BidhaanBooking />} />
        <Route path='/portfolio/buundo-jobs' element={<BuundoJobs />} />
        <Route path='/portfolio/zakariye' element={<Zakariye />} />
        <Route path='/portfolio/ashqarar' element={<Ashqarar />} />
        <Route path='/portfolio/submalco' element={<Submalco />} />
        <Route path='/portfolio/fatah-tech' element={<FatahTech />} />
        <Route path='/portfolio/smartplus-advert' element={<SmartPlus />} />
        <Route path='/portfolio/dalboon' element={<Dalboon />} />
        <Route path='/*' element={<NotFound />} />
      </Route>
    )
  );

  return (
    <RouterProvider router={router}>
       <TitleManager /> 
      {/* ScrollToTop needs to be inside RouterProvider to access routing context */}
      <ScrollToTop />  
    </RouterProvider>
  );
}

export default App;
